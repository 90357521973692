import React, { useState, useEffect } from 'react';
import { useMoralis, useNFTBalances } from 'react-moralis';
import { Link, VStack, color } from '@chakra-ui/react';
import {
  Box,
  Button,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Collapse,
} from '@chakra-ui/react';
import Moralis from 'moralis-v1';
import './wallet.css';
import { link } from 'fs';

const RecklessWallets = Moralis.Object.extend({
  className: 'RecklessWallets'
});

const App = () => {

  const { account } = useMoralis();
  const [address, setAddress] = useState('');
  const [assets, setAssets] = useState([]);

  const [wallets, setWallets] = useState(['']); // State to store the input address
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showDetails, setShowDetails] = useState(false);


  
  useEffect(() => {
    const WalletsfromStorage1 = localStorage.getItem('walletsStorage');
    const safeJSONParse = (str) => {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.error("Failed to parse JSON", e);
        return null;
      }
    }

    if (WalletsfromStorage1) {
      console.log(WalletsfromStorage1);

      
      const parsedWallets = safeJSONParse(WalletsfromStorage1);
      setTimeout(() => {
        if (parsedWallets) {
          setWallets(parsedWallets);
        }
      }, 1000);
    
    }
  }, []);
  
  useEffect(() => {
    if (wallets && wallets.length > 0 && wallets[0]!==''){
      localStorage.setItem('walletsStorage', JSON.stringify(wallets)); // Store the array as a JSON string.
      console.log(wallets);
    }
  }, [wallets]);
 
  
  useEffect(() => {
    if (account && !address && !wallets.includes(account) || (buttonClicked && wallets.length === 0)) {
      setWallets([account || '']);
    } else if (wallets.length > 0 && wallets[0] === "" && !account && address && !wallets.includes(address) && buttonClicked) {
      setWallets([address]);
    } else if (wallets.length > 0 && wallets[0].includes("0x") && !account && address && buttonClicked) {
      setWallets([...wallets, address]); // Use spread operator to add the address to the array.
    } else if (account && address && !wallets.includes(account)) {
      setWallets([...wallets, account]);
    } else if (account && address && !wallets.includes(address)) {
      setWallets([...wallets, address]); // Use spread operator to add the address to the array.
    }
    
    console.log(wallets);
  }, [account, buttonClicked]);
/*
  useEffect(() => {

    const SettingWallets = async () => {
    
      if (wallets){
  //
     
    // Query the previous Tokens of the user
  
   
    const queryO = await new Moralis.Query(RecklessWallets);
    await queryO.equalTo('player', address);
  
    try {
     
      const previousOneManArmyObject = await queryO.first();
  
  
        
          if (!previousOneManArmyObject && address!==''){
        const newWalletObject = new RecklessWallets();
        newWalletObject.set('player', address);
        newWalletObject.set('wallets', wallets);
      
        await newWalletObject.save();
        console.log('First Wallets saved', wallets)
      setWallets(wallets)}
      
  
    
  
  
  
         else  {
         
        const previousWallets = await previousOneManArmyObject.get('wallets');
       
  
        setWallets(previousWallets);
        console.log('previous wallets fetched', previousWallets);
        } 
       
       } catch (error) {
      console.error('Error querying wallets:', error);
    };}}
    
  SettingWallets();
  console.log('searching wallets...')
  
  }, [account && address])

*/





  useEffect(() => {
    
    if (process.env.REACT_APP_MORALIS_API_KEY && wallets.length > 0 && (address || account || wallets !=='' ) ) {
      fetchAssets();
    }
  }, [account, buttonClicked, wallets]);

  useEffect(() => {
    if (sortBy) {
      const sortedAssets = [...assets].sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setAssets(sortedAssets);
    }
  }, [sortBy, sortDirection]);

  const fetchAssets = async () => {
    
    try {
      const promises = wallets.map(wallet =>
        fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${wallet}/tokens?chain=cronos&exclude_spam=false&exclude_unverified_contracts=false`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY
          },
        }).then(response => response.json())
      );

      const results = await Promise.all(promises);
      const mergedData = results.reduce((merged, result) => {
        result.result.forEach(item => {
          const existingItemIndex = merged.findIndex(mergedItem => mergedItem.name === item.name);
          if (existingItemIndex !== -1) {
            merged[existingItemIndex].balance_formatted += item.balance_formatted;
            merged[existingItemIndex].balance += item.balance;
            merged[existingItemIndex].usd_value += item.usd_value;
          } else {
            merged.push(item);
          }
        });
        return merged;
     
      }, []);
      console.log(mergedData)
      setAssets(mergedData);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

   const handleInputChange = (e) => {



    if (buttonClicked ){
      setAddress(e.target.value);
     setWallets([...wallets,address])}
     else {    setAddress(e.target.value);}
    };

  const handleButtonClick = () => {
   
    

setButtonClicked(true)
setTimeout(() => {
  setButtonClicked(false)
}, 1000);
   
  };

  const calculateTotalValueUSD = () => {
    return assets.reduce((total, asset) => total + asset.usd_value, 0).toFixed(2);
  };

  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnName);
      setSortDirection('asc');
    }
  };
  const calculateDailyChangeInUSD = (asset) => {
    if (asset) {
     
        return (asset.usd_value - ((asset.usd_value) / (1 + (asset.usd_price_24hr_percent_change / 100)))).toFixed(2);
    }
}
const calculateDailyTotalChangeInUSD = () => {
    return assets.reduce((total, asset) => total + parseFloat(calculateDailyChangeInUSD(asset)), 0).toFixed(2);
}
  const handleToggle = () => {
    setShowDetails(!showDetails);



handleSort('value');
  };


  
  useEffect(() => {
    const walletDiv = document.getElementById('Portfolio');
    if (walletDiv) {
      walletDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, );

  return (
    <Box  color={'white'} fontSize='1vmax' alignItems={'center'} justifyItems={'center'} textAlign={'center'}  >
      <Text id='Portfolio' textAlign={'center'} fontSize={'2vmax'}  mb="4">
        Your Cronos Portfolio
      </Text>
      <Box className="portfolio-controls" mb="4">
        <Input
          type="text"
          value={address}
          onChange={handleInputChange}
          placeholder="Add Wallets"
          fontSize={'1vmax'}
          mr="1"
          width={'50%'}
          boxSize={'1,3'}
        
        />
        <Button  fontSize={'1.2vmin'} colorScheme="orange"  width='fit-content' onClick={handleButtonClick}>
          Save wallet and Fetch Assets
        </Button>
      </Box>
      <Box paddingBottom={'5vmin'} fontSize={'1vmax'} className="wallets" mb="1">
      <Button  padding={'1vmax'} margin={'1vmax'} boxShadow='0 0 25px orangered' width={'fit-content'} height={'3.0vmax'} variant="outline" _hover={{textColor:'black', backgroundColor:'white'}}   color= 'white' mt={6} fontSize={'1.2vmin'}    as={Link} href= {!address && !account? 'https://www.cronoscan.com' : `https://cronoscan.com/address/${address? address: account? account : null}`} >

          Tracked Wallets
        </Button>
        <ul style={{ rowGap:'1px', height:'fit-content'}}>
          {wallets.map((wallet, index) => (
            <li  style={{padding:'1px', height:'fit-content'}} key={index}>{wallet}</li>
          ))}
        </ul>
     
        <Text  position={'sticky'} top='0' alignSelf={'center'} padding={'2vmax'} margin={'1vmax'} textShadow='0 0 30px orangered' width={'full'} height={'3.0vmax'} variant="outline" as={Text}  color= 'white' mt={6} fontSize={'1.2vmin'}    >
        Total value : <span  style={{ textUnderlineOffset:'1px' ,color:'green', padding:'2vmax', fontSize:'2.5vmax', }} >     ${calculateTotalValueUSD()} <span style={{ textUnderlineOffset:'1px' , padding:'2vmax', fontSize:'1.5vmax', }}  className={calculateDailyTotalChangeInUSD() < 0 ? 'negative' : 'positive'}>{calculateDailyTotalChangeInUSD() < 0 ? "" : '+'}{calculateDailyTotalChangeInUSD()}$</span> </span>
</Text>
      </Box>
  
      <Box
        content="contain"
        width="100%"
        overflow="auto" // Add this line to handle overflow
        className="nft-cards"
        justifyContent="space-evenly"
      >
        <Table
          justifyContent="center"
          marginLeft="10vmin"
          marginRight="10vmin"
          padding="2vmax"
          alignSelf="center"
          alignItems="center"
          overflowX="auto" // Add this line to handle horizontal overflow
        >
          <Thead justifyContent="space-evenly" id="asset-table">
            <Tr>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingTop="2vmax"
                borderBottom="hidden"
                fontSize="1.5vmin"
                onClick={() => handleSort('name')}
              >
                Name ↕️
              </Th>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingTop="2vmax"
                borderBottom="hidden"
                fontSize="1.5vmin"
                onClick={() => handleSort('usd_value')}
              >
                Value ↕️
              </Th>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingTop="2vmax"
                borderBottom="hidden"
                fontSize="1.5vmin"
                onClick={() => handleSort('usd_price_24hr_percent_change')}
              >
                24h Change ↕️
              </Th>
            </Tr>
            <Tr>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingBottom="2vmax"
                borderBottom="4px solid grey"
                fontSize="1.5vmin"
              >
                CHART
              </Th>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingBottom="2vmax"
                borderBottom="4px solid grey"
                fontSize="1.5vmin"
                onClick={() => handleSort('balance')}
              >
                Balance ↕️
              </Th>
              <Th
                _hover={{ textShadow: '5px 5px 5px #0056b3' }}
                paddingBottom="2vmax"
                borderBottom="4px solid grey"
                fontSize="1.5vmin"
                onClick={() => handleSort('usd_price')}
              >
                Price ↕️
              </Th>
            </Tr>
          </Thead>
          {assets.map((asset) =>
            asset.usd_value > 0 ? (
              <Tbody scale="0.1" id="assets" key={asset.token_address}>
                <Tr id="assets" paddingTop="2vmax" rowGap="1px" fontSize="1.8vmax">
                  <Td
                    textAlign="center"
                    maxWidth="10px"
                    lineHeight="2.1vmax"
                    rowGap="1px"
                    paddingTop="1.8vmax"
                    borderBottom="hidden"
                    fontSize="2.0vmax"
                    onClick={handleToggle}
                  >
                    {asset.symbol.slice(0, 20)} {asset.symbol.length > 20 ? '...' : ''}
                  </Td>
                  <Td
                    textAlign="center"
                    paddingTop="2vmax"
                    color="gold"
                    borderBottom="hidden"
                    fontSize="1.8vmax"
                  >
                    ${parseFloat(asset.usd_value?.toFixed(2))}
                  </Td>
                  <Td
                    rowGap="1vmax"
                    textAlign="center"
                    paddingTop="2vmax"
                    borderBottom="hidden"
                    fontSize="1.3vmax"
                    className={asset.usd_price_24hr_percent_change < 0 ? 'negative' : 'positive'}
                  >
                    {parseFloat(asset.usd_price_24hr_percent_change?.toFixed(2))}% ={' '}
                    {calculateDailyChangeInUSD(asset)}
                  </Td>
                </Tr>

                <Tr rowGap="0px">
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.3vmax">
                    <Link
                      lineHeight="2vmax"
                      textAlign="center"
                      textColor="orangered"
                      href={`https://dexscreener.com/cronos/${asset.token_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {asset.name.slice(0, 20)} {asset.name.length > 20 ? '...' : ''} 📈
                    </Link>
                  </Td>
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.3vmax">
                    {parseFloat(asset.balance_formatted).toFixed(2)}
                  </Td>
                  <Td textAlign="center" borderBottom="2px outset grey" fontSize="1.3vmax">
                    ${parseFloat(asset.usd_price?.toFixed(9))}
                  </Td>
                </Tr>
              </Tbody>
            ) : null
          )}
        </Table>
      </Box>
    </Box>

  )}
export default App;