import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Header from './layout/Header';
import Main from './layout/Main';
import Footer from './layout/Footer';
import { VStack, Spacer } from '@chakra-ui/react';
import { Nfts } from './views/Nfts';
import Vote from './views/vote';
import Vote2 from './views/LegendsVote';
import {Fallen} from './views/Fallen';
import {Legends} from './views/Legends';

import {LRanking} from './views/LRanking'
import About from './views/About';

import GameLoader from './views/GameLoader';


import Skilltree from './views/game/skilltree';
import ClickerGame from './views/game/Clicker/ClickerGame';
import RampageOneManArmy_R3 from './views/game/OneManArmy';
import GameLoader_all from './views/GameLoader_all';
import teamUnlock from './views/game/teamUnlock';
import Moralis from 'moralis'
import DemoGameLoader from './views/GameLoader_demo'
import Demo from './views/game/Demo'
import Wallet from './views/Wallet';



function App() {
  


    

  return (
    <VStack  alignSelf={'center'} alignItems="center" width={'100%'} minHeight="100vh">
      <Header />
      <Main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="nfts" element={<Nfts />} />
          <Route path="Fallen" element={<Fallen  />} />
          <Route path="LegendsVote" element={<Vote2 />} />
          <Route path="Vote" element={<Vote />} />
          <Route path="Legends" element={<Legends />} />
       
          <Route path="ranking" element={<LRanking />} />
          <Route path="Rampage/" element={<GameLoader />} />
          <Route path="Rampage/Demo" element={<DemoGameLoader />} />
          <Route path="About" element={<About />} />
          <Route path="Rampage/DemoGame" element={<Demo />} />
          <Route path="Rampage/Game" element={<RampageOneManArmy_R3 />} />
          <Route path="Wallet" element={<Wallet />} />
        
          <Route path="ClickerGame" element={<ClickerGame />} />
        
        </Routes>
      </Main>
      <Spacer />
      <Footer />
    </VStack>
  );
}

export default App;
