import { Button, HStack, VStack} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ children, to }: { children: string; to: string }) => {
  return (
    <Button padding='1' boxSize={'-moz-fit-content'} fontSize={'1.1vw'} color={'grey'} variant="ghost" as={Link} to={to} fontWeight="bold">
      {children}
    </Button>
  );
};
export const Navigation = () => {
  return (
    <VStack>
    <HStack textAlign={'center'} width= 'full' wrap="nowrap"   direction="column" >
      <Item to="/">| HOME |</Item>
      <Item to="/about">| ABOUT |</Item>

      <Item to="/vote">| VOTE |</Item>
      <Item to="/fallen">| FALLEN |</Item>
      <Item to="/ranking">| RANKING |</Item>
      <Item to="Rampage/Demo">| RAMPAGE DEMO |</Item>
      </HStack>
      
      <HStack>
      <Item to="/nfts">| RECKLESS NFTS HELD |</Item>
      <Item to="/legendsvote">| LEGENDS VOTE |</Item>
     
      <Item to="Rampage/">| RECKLESS RAMPAGE |</Item>
      <Item to="/wallet">| PORTFOLIO TRACKER |</Item>
    </HStack>
    
    </VStack>
  );
};
